import { useStore } from "./index.ts"

export const useStyles = defineStore('styles', {
  state: () => ({
   //store:useStore()
  }),
  actions:{
    // const store = useStore()
    // return {

    // }
  },
  getters:{
      ThemeGeneralTextoCss: (state) => {
        const store = useStore()
        return store.NOTARIA.Theme?.General?.TextoCss ? store.NOTARIA.Theme.General.TextoCss : {}
      },
      ThemeHomeService:(state) => {
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.Servicios? store.NOTARIA.Theme.Home.Servicios: {}
      },
      ThemeHomeSocialChat:(state) => {
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.SocialChat? store.NOTARIA.Theme.Home.SocialChat: {}
      },
      ThemeHomeBlog:(state)=>{
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.Blog? store.NOTARIA.Theme.Home.Blog: {}
      },
      ThemeHomeGallery:(state)=>{
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.Gallery? store.NOTARIA.Theme.Home.Gallery: {}
      },
      ThemeHomePalabrasNotario:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.PN? store.NOTARIA.Theme.Home.PN: {}
      },
      ThemeHomeBanner:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Home?.Banner? store.NOTARIA.Theme.Home.Banner: {}
      },
      ThemeSectionNotario:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Notarios? store.NOTARIA.Theme.Notarios: {}
      },
      ThemeSectionTeam:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Team? store.NOTARIA.Theme.Team: {}
      },
      ThemeSectionServicios:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Servicios? store.NOTARIA.Theme.Servicios: {}
      },
      ThemeSectionContacto:(state)=>
      {
        const store = useStore()
        return store.NOTARIA.Theme?.Contacto? store.NOTARIA.Theme.Contacto: {}
      },
    }
})